<template>
  <div>
    <!-- Navigation Help -->
    <div v-if="this.$route.name === 'Help'">
      <nav class="navbar navbar-expand-lg bg-primary text-uppercase fixed-top navbar-hide-on-scroll"
           id="mainNavHelp"
           :class="{ 'element-hide-top': isMobile && scrollDown, 'element-show-top': isMobile && scrollUp, 'navbar-shrink': !shrinkNavbar }">
        <div class="container">
          <a href="https://www.brabantcollectie.nl">
            <img src="../assets/brand_logo/logo_BC_TiU_trans.svg" alt="BC-logo" class="mr-3 logo-bc-resize">
          </a>
          <a class="navbar-brand mr-auto" href="https://www.brabantcollectie.nl">
            Brabant-Collectie
          </a>
          <button class="navbar-toggler text-uppercase font-weight-bold bg-secondary text-white rounded"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarResponsiveHelp"
                  aria-controls="navbarResponsiveHelp"
                  aria-expanded="false"
                  aria-label="Toggle navigation">
            Menu
            <i class="fas fa-bars"></i>
          </button>
          <div class="collapse-navbar navbar-collapse" id="navbarResponsiveHelp">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item mx-0 mx-lg-1">
                <a class="nav-link py-3 px-0 p-lg-3 rounded"
                   href="#search-section">Zoeken</a>
              </li>
              <li class="nav-item mx-0 mx-lg-1">
                <a class="nav-link py-3 px-0 p-lg-3 rounded"
                   href="#presentation-section">Presentatie</a>
              </li>
              <li class="nav-item mx-0 mx-lg-1 my-auto">
                <a class="nav-link py-3 px-0 d-inline-block"
                     @click="windowClose"
                >Sluiten<i class="fas fa-times ml-2"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
    <!-- Navigation Home -->
    <div v-else>
      <nav class="navbar navbar-expand-lg bg-primary text-uppercase fixed-top navbar-hide-on-scroll"
           id="mainNav"
           :class="{ 'element-hide-top': isMobile && scrollDown, 'element-show-top': isMobile && scrollUp, 'navbar-shrink': !shrinkNavbar }">
        <div class="container">
          <a href="https://www.brabantcollectie.nl" target="_blank">
            <img src="../assets/brand_logo/logo_BC_TiU_trans.svg" alt="BC-logo" class="mr-3 logo-bc-resize">
          </a>
          <a class="navbar-brand js-scroll-trigger mr-auto" href="https://www.brabantcollectie.nl" target="_blank">
            Brabant-Collectie
          </a>
          <button class="navbar-toggler text-uppercase font-weight-bold bg-secondary text-white rounded"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarResponsive"
                  aria-controls="navbarResponsive"
                  aria-expanded="false"
                  aria-label="Toggle navigation">
            Menu
            <i class="fas fa-bars"></i>
          </button>
          <div class="collapse navbar-collapse" id="navbarResponsive">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item mx-0 mx-lg-1">

                <router-link to="help" class="nav-link py-3 px-0 p-lg-3" target="_blank"
                >Help
                </router-link>
              </li>
              <li class="nav-item mx-0 mx-lg-1">
                <a class="nav-link py-3 px-0 px-lg-3"
                   href="https://www.tilburguniversity.edu/nl/campus/brabantcollectie/over" target="_blank">Over</a>
              </li>
              <li class="nav-item mx-0 mx-lg-1">
                <a class="nav-link py-3 px-0"
                   href="https://www.tilburguniversity.edu/nl/campus/brabantcollectie/contactgegevens"
                   target="_blank">Contact</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NavBar',
  data() {
    return {
      isMobile: false,
      scrollUp: false,
      scrollDown: false,
      shrinkNavbar: true,
      lastScrollPosition: 0,
    }
  },
  methods: {
    onScroll() {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      if (currentScrollPosition < 0) {
        return
      }

      // Stop executing this function if the difference between
      // current scroll position and last scroll position is less than some offset
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) === 0) {
        return
      }

      this.scrollDown = currentScrollPosition > this.lastScrollPosition
      this.scrollUp = currentScrollPosition < this.lastScrollPosition
      this.shrinkNavbar = currentScrollPosition < this.lastScrollPosition

      let w = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
      this.isMobile = w < 992
      this.lastScrollPosition = currentScrollPosition
    },
    windowClose() {
      window.open('', '_parent', '')
      window.close()
    }
  },
  created() {
    this.$root.$refs.NavBar = this
  }
  ,
  mounted() {
    window.addEventListener('scroll', this.onScroll)
  }
  ,
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  }
}
</script>

<style lang="scss">
@import "../scss/base/annimations";
@import "../scss/components/navbar";

a.nav-link {
  cursor: pointer;
}

</style>
