import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home'

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home
        },
        {
            path: '/help',
            name: 'Help',
            component: () => import(/* webpackChunkName: "help" */ '../views/Help.vue')
        },
        {
            path: '/disclaimer',
            name: 'Disclaimer',
            component: () => import(/* webpackChunkName: "disclaimer" */ '../views/Disclaimer.vue')
        },
        {
            path: '/copyright',
            name: 'Copyright',
            component: () => import(/* webpackChunkName: "copyright" */ '../views/Copyright.vue')
        },
        {
            path: '/privacy',
            name: 'Privacy',
            component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue')
        },
        {
            path: '/cookies',
            name: 'Cookies',
            component: () => import(/* webpackChunkName: "cookies" */ '../views/Cookies.vue')
        }
    ]
})